.pptx-dropzone {
  width: 100vw;
  height: 100vh;

  .drop-container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .drop-container-prompt {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-family: MetricHPESemiBold;
    text-align: center;
    width: 300px;
  }

  .drop-container-text {
    display: flex;
    width: 100%;
    max-height: 100%;
    //overflow-y: auto;
  }

  .dropzone {
    width: calc(100% - 44px);
    height: calc(100% - 44px);
    position: absolute;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s;
    cursor: pointer;
  }

  .error {
    color: red;
  }

  .loading {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 1;
  }

  .processing {
  }

  .progress-content {
    margin-top: 10px;
    text-align: left;

    :first-of-type {
      color: black;
    }
  }

  .slide-count {
    position: absolute;
    font-size: 12px;
    left: 5px;
    bottom: 5px;
  }

  .uploading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .uploading-gif {
    width: 100%;
    max-width: 325px;
  }

  .version {
    position: absolute;
    font-size: 12px;
    right: 5px;
    bottom: 5px;
  }
}
