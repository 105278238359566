html,
body,
#root,
.App {
  height: 100%;
}

body {
  margin: 0;
  font-family: MetricHPELight;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: MetricHPESemibold;
}

.flex-row-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// remove blue outline in chrome
*:focus {
  outline: 0 !important;
}
